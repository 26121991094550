import * as React from 'react';
import { InterpolationFunction, Styles, ThemeProps } from 'styled-components';
import styled, { css, ResolvedThemeInterface, StyledComponentClass } from '../styled-components';
const headerFontSize = {
  1: '1.85714em',
  2: '1.57143em',
  3: '1.27em'
};
export const headerCommonMixin = level => css(["font-family:", ";font-weight:400;font-size:", ";"], props => props.theme.headingsFont.family, headerFontSize[level]);
export const H1 = styled.h1(["", ";color:", ";"], headerCommonMixin(1), props => props.theme.colors.main);
export const H2 = styled.h2(["", ";color:black;"], headerCommonMixin(2));
export const H3 = styled.h2(["", ";color:black;"], headerCommonMixin(3));
export const UnderlinedHeader = styled.h5(["border-bottom:1px solid rgba(38,50,56,0.3);margin:1em 0 1em 0;color:rgba(38,50,56,0.5);font-weight:normal;text-transform:uppercase;font-size:0.929em;line-height:20px;"]);