import { AnchorHTMLAttributes, ClassAttributes, HTMLAttributes } from 'react';
import { H1, MiddlePanel } from '../../common-elements';
import styled, { ResolvedThemeInterface, StyledComponentClass } from '../../styled-components';
const delimiterWidth = 15;
export const ApiInfoWrap = MiddlePanel;
export const ApiHeader = H1.extend`
  margin-top: 0;
  margin-bottom: 0.5em;
`;
export const DownloadButton = styled.a(["border:1px solid ", ";color:", ";font-weight:normal;margin-left:0.5em;padding:4px 8px 4px;display:inline-block;text-decoration:none;"], props => props.theme.colors.main, props => props.theme.colors.main);
export const InfoSpan = styled.span(["&::before{content:'|';display:inline-block;opacity:0.5;width:", "px;text-align:center;}&:last-child::after{display:none;}"], delimiterWidth);
export const InfoSpanBoxWrap = styled.div(["overflow:hidden;"]);
export const InfoSpanBox = styled.div(["display:flex;flex-wrap:wrap;margin-left:-", "px;"], delimiterWidth);